import Cookies from "js-cookie";
import { API_URL } from "../../components/shared";

/**
 * Makes an API request.
 * 
 * @param {Object} params - The parameters for the API request.
 * @param {string} params.path - The API endpoint path.
 * @param {string} [params.method="GET"] - The HTTP method (optional, defaults to "GET").
 * @param {any} [params.body] - The request body (optional).
 * @param {'blob' | 'text' | 'json'} [params.responseType] - The response type (optional).
 * 
 * @returns {Promise<{data?: any, error?: string}>} The response data or error.
 */
export const api = async ({ path, method = "GET", body, responseType }) => {
  const token = Cookies.get("login-token");

  const settings = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (body && !(body instanceof FormData)) {
    settings.headers["Content-Type"] = "application/json";
    settings.body = JSON.stringify(body);
  } else if (body) {
    settings.body = body; // Let the browser set the Content-Type for FormData
  }

  const res = await fetch(`${API_URL}/${path}`, settings);

  let json;
  try {
    json = await res.json();
  } catch (error) {
    json = null;
  }

  // error
  if (!res.ok) {
    return {
      error: (json && json.detail) || res.statusText || "Unknown error",
    };
  }
  // no content
  if (res.status === 204) {
    return {};
  }

  if (responseType === 'blob') {
    const blob = await res.blob();
    return { data: blob };
  }

  if (json === null) {
    return {};
  }

  return json;
};

export const reportApi = async ({ path, method = "GET", body }) => {
  const res = await fetch('https://h3yql61tgd.execute-api.us-east-1.amazonaws.com/enterprise-reporting-app-prod/dashboard/reports', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
  return res.json()
};

export const get = (path) => api({ path });
export const post = (path, body) => api({ path, method: "POST", body });
export const patch = (path, body) => api({ path, method: "PATCH", body });
export const del = (path) => api({ path, method: "DELETE" });
